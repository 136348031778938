import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs'
import { UserService } from './user.service';
import { OnDestroy } from '@angular/core';

@Injectable()
export class MessagingService implements OnDestroy {

  currentMessage = new BehaviorSubject(null);
  subscription: Subscription;
  applicationId: string = "tiqdev";
  phone: string = "";
  token: string = "";
  constructor(
    private userService: UserService,
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }
  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(username, token) {
    this.userService.getConfigurationByName("applicationId").subscribe(data => {
      if (data) {
        this.applicationId = data.result;
      }
    }, error => { });
    this.userService.updateDevicetoken(username, {
      "token": token,
      "authorized": true,
      "applicationId": this.applicationId
    }).subscribe(data => {
      console.log("Successfully update device token");
    }, error => {
      console.log("Faild to update device token");
    });
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        if (token) {
          console.log(token);
          this.phone = userId;
          this.token = token
          this.updateToken(userId, token);
        }
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.subscription = this.angularFireMessaging.messages.subscribe(
      (payload) => {
        // console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.token) {
      this.userService.updateDevicetoken(this.phone, {
        "token": this.token,
        "authorized": false,
        "applicationId": this.applicationId
      }).subscribe(data => {
        console.log("Successfully update device token");
      }, error => {
        console.log("Faild to update device token");
      });
      this.subscription.unsubscribe();
    }
  }
}
