import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { User, Bank, University, Journal, EventRegistrant, Record } from '../models/user.model'
import { Observable } from 'rxjs'
import 'rxjs/Rx'
import { ServerResponse } from '../models/server-reponse.model'
import { CookieService } from 'ngx-cookie-service'

const ApiEndpoint = '/api/v2/users'
const BankEndpoint = '/api/v2/banks'
const ApiFiles = '/api/v2/files'
const ApiImages = '/api/v2/files/images/'
const EventImage = '/api/v2/attachments/'
const ApiConfig = '/api/v2/configurations'
const UniversityEndpoint = '/api/v2/universities'
const RecordEndpoint = '/api/v2/records'
const JournalEndpoint = '/api/v2/journals'

@Injectable()
export class UserService {
    constructor(private http: HttpClient, private cookieService: CookieService) { }
    exportRegisteredStudents(params? : HttpParams): Observable<any> {
        return this.http.get(ApiEndpoint + 'regstudent', {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            }),
            params: params
        })
            .map(response => response as any || null)
    }
    getHttpHeaders() {

        return new HttpHeaders({
            'Authorization': 'Bearer ' + this.cookieService.get('access_token'),
            'Content-Type': 'application/json',
            'accept': '*/*'
        })
    }

    getAllUsers(role: string, params? : HttpParams): Observable<ServerResponse<User[]>> {
        if (!params) params = new HttpParams().set('role', role)
        return this.http.get(ApiEndpoint, {
            params: params,
            headers: this.getHttpHeaders()
        })

        .map(response => response as ServerResponse<User[]> || null)
    }

    getUserByUsername(username: string): Observable<ServerResponse<User>> {
        return this.http.get(ApiEndpoint + `/${username}`, {
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<User> || null)
    }
    getUsersByPIM(pim: string): Observable<ServerResponse<User>> {
        return this.http.get(ApiEndpoint + `/byPIM/${pim}`, {
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<User> || null)
    }
    loadAllUserToSendBroadCast() : Observable<ServerResponse<User>> {
        return this.http.get(ApiEndpoint + '/load-all', {headers: this.getHttpHeaders()}).map(res => res as ServerResponse<User>)
    }
    getUsersByTRM(trm: string): Observable<ServerResponse<User>> {
        return this.http.get(ApiEndpoint + `/byTRM/${trm}`, {
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<User> || null)
    }
    getUsersByWTI(wti: string): Observable<ServerResponse<User>> {
        return this.http.get(ApiEndpoint + `/byWTI/${wti}`, {
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<User> || null)
    }

    deleteUserByUsername(username: string): Observable<ServerResponse<User>> {
        return this.http.delete(ApiEndpoint + `/${username}`, {
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<User> || null)
    }

    createNewUser(ob: object): Observable<ServerResponse<object>> {
        return this.http.post(ApiEndpoint, ob, {
            headers: this.getHttpHeaders()
        }).map(response => response as ServerResponse<object> || null);
    }

    uploadFile(fileToUpload: FormData): Observable<ServerResponse<object[]>> {
        return this.http.post(ApiFiles, fileToUpload, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            })
        })
            .map(response => response as ServerResponse<object[]> || null)
    }

    getImage(fileId: string): Observable<Blob> {
        return this.http.get(`${ApiImages}${fileId}`, {
            headers: this.getHttpHeaders(),
            responseType: 'blob'
        });
      }

      updateUserInfo(username: string, ob: object): Observable<ServerResponse<any>> {
          return this.http.put(`${ApiEndpoint}/${username}`, ob, {
            headers: this.getHttpHeaders()
          }).map(response => response as ServerResponse<any> || null);
      }

      updateDevicetoken(username: string, ob: object): Observable<ServerResponse<any>> {
        return this.http.put(`${ApiEndpoint}/${username}/deviceToken`, ob, {
          headers: this.getHttpHeaders()
        }).map(response => response as ServerResponse<any> || null);
    }

      changePassword(username: string, ob: object): Observable<ServerResponse<any>> {
        return this.http.put(`${ApiEndpoint}/${username}/password`, ob, {
            headers: this.getHttpHeaders()
          }).map(response => response as ServerResponse<any> || null);
      }

      getConfiguration() {
        return this.http.get(`${ApiConfig}`, {
            headers: this.getHttpHeaders(),
        }).map(response => response as ServerResponse<any> || null);
      }

      getConfigurationByName(name: string) {
        return this.http.get(`${ApiConfig}/${name}`, {
            headers: this.getHttpHeaders(),
        }).map(response => response as ServerResponse<any> || null);
      }

    getAllBanks(params? : HttpParams): Observable<ServerResponse<Bank[]>> {
        return this.http.get(BankEndpoint, {
            params: params,
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<Bank[]> || null)
    }
    createBank(ob: object): Observable<ServerResponse<object>> {
        return this.http.post(BankEndpoint, ob, {
            headers: this.getHttpHeaders()
        }).map(response => response as ServerResponse<object> || null);
    }
    updateBank(bankId: string, ob: object): Observable<ServerResponse<any>> {
        return this.http.put(`${BankEndpoint}/${bankId}`, ob, {
          headers: this.getHttpHeaders()
        }).map(response => response as ServerResponse<any> || null);
    }
    getAllUniversities(params? : HttpParams): Observable<ServerResponse<University[]>> {
        return this.http.get(UniversityEndpoint, {
            params: params,
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<University[]> || null)
    }
    getUniversity(id: string,): Observable<ServerResponse<University>> {
        return this.http.get(`${UniversityEndpoint}/${id}`, {
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<University> || null)
    }
    deleteUniversity(id: string,): Observable<ServerResponse<University>> {
        return this.http.delete(`${UniversityEndpoint}/${id}`, {
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<University> || null)
    }
    createUniversity(ob: object): Observable<ServerResponse<object>> {
        return this.http.post(UniversityEndpoint, ob, {
            headers: this.getHttpHeaders()
        }).map(response => response as ServerResponse<University> || null);
    }
    updateUniversity(universityId: string, ob: object): Observable<ServerResponse<any>> {
        return this.http.put(`${UniversityEndpoint}/${universityId}`, ob, {
          headers: this.getHttpHeaders()
        }).map(response => response as ServerResponse<University> || null);
    }
    getAllRecords(params? : HttpParams): Observable<ServerResponse<Record[]>> {
        return this.http.get(RecordEndpoint, {
            params: params,
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<Record[]> || null)
    }
    getRecord(id: string,): Observable<ServerResponse<Record>> {
        return this.http.get(`${RecordEndpoint}/${id}`, {
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<Record> || null)
    }
    deleteRecord(id: string,): Observable<ServerResponse<Record>> {
        return this.http.delete(`${RecordEndpoint}/${id}`, {
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<Record> || null)
    }
    createRecord(ob: object): Observable<ServerResponse<object>> {
        return this.http.post(RecordEndpoint, ob, {
            headers: this.getHttpHeaders()
        }).map(response => response as ServerResponse<Record> || null);
    }
    updateRecord(RecordId: string, ob: object): Observable<ServerResponse<any>> {
        return this.http.put(`${RecordEndpoint}/${RecordId}`, ob, {
          headers: this.getHttpHeaders()
        }).map(response => response as ServerResponse<Record> || null);
    }
    exportEventRegistrants(params? : HttpParams): Observable<any> {
        return this.http.get(`${UniversityEndpoint}/export/registrants/all`, {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            }),
            params: params
        })
            .map(response => response as any || null)
    }
    exportEventAttendees(eventId : string): Observable<any> {
        return this.http.get(`${UniversityEndpoint}/${eventId}/export-statistic`, {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            }),
        })
            .map(response => response as any || null)
    }
    getEventRegistrants(eventId : string): Observable<any> {
        return this.http.get(`${UniversityEndpoint}/${eventId}/public-registrants`, {
            headers: this.getHttpHeaders()
          }).map(response => response as ServerResponse<EventRegistrant[]> || null);
    }
    importEventRegistrants(eventId: string, formData: FormData): Observable<ServerResponse<{total: number, duplication: {total: number, phones: string[]}}>> {
        return this.http.post(`${UniversityEndpoint}/${eventId}/import-registrants`, formData, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            })
        })
            .map(response => response as ServerResponse<{total: number, duplication: {total: number, phones: string[]}}> || null)
    }
    uploadEventImage(fileToUpload: FormData): Observable<ServerResponse<object[]>> {
        return this.http.post(EventImage, fileToUpload, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            })
        })
            .map(response => response as ServerResponse<object[]> || null)
    }
    getEventImage(fileId: string): Observable<Blob> {
        return this.http.get(`${EventImage}${fileId}`, {
            headers: this.getHttpHeaders(),
            responseType: 'blob'
        });
    }
    deleteEventImage(fileId: string): Observable<Blob> {
        return this.http.delete(`${EventImage}${fileId}`, {
            headers: this.getHttpHeaders(),
            responseType: 'blob'
        });
    }
    getAllJournals(params? : HttpParams): Observable<ServerResponse<Journal[]>> {
        return this.http.get(JournalEndpoint, {
            params: params,
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<Journal[]> || null)
    }
    getJournal(id: string,): Observable<ServerResponse<Journal>> {
        return this.http.get(`${JournalEndpoint}/${id}`, {
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<Journal> || null)
    }
    deleteJournal(id: string,): Observable<ServerResponse<Journal>> {
        return this.http.delete(`${JournalEndpoint}/${id}`, {
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<Journal> || null)
    }
    createJournal(ob: object): Observable<ServerResponse<object>> {
        return this.http.post(JournalEndpoint, ob, {
            headers: this.getHttpHeaders()
        }).map(response => response as ServerResponse<Journal> || null);
    }
    updateJournal(JournalId: string, ob: object): Observable<ServerResponse<any>> {
        return this.http.put(`${JournalEndpoint}/${JournalId}`, ob, {
          headers: this.getHttpHeaders()
        }).map(response => response as ServerResponse<Journal> || null);
    }
}


