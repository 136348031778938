import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'confirm-delete-user',
  templateUrl: './confirm-delete-user.component.html',
  styleUrls: ['./confirm-delete-user.component.scss']
})
export class ConfirmDeleteUserComponent implements OnInit {

  @Input() 
  title: string;
  @Input()
  message: string;


  constructor(public ref: NbDialogRef<ConfirmDeleteUserComponent>) { }

  ngOnInit() {
    //document.querySelector(".content").innerHTML = this.message;
  }

  dismiss() {
    this.ref.close();
  }

  confirm() {
    this.ref.close({
      action: "confirmed",
    });
  }
}
