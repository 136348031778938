import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'nb-name-prompt',
  template: `
    <nb-card>
      <nb-card-header>Confirmation</nb-card-header>
      <nb-card-body>{{content1}}</nb-card-body>
      <nb-card-footer>
      <button class="float-left" nbButton status="danger" (click)="cancel()">No</button>
      <button class="float-right" nbButton status="success" (click)="submit(true)">Yes</button>        
      </nb-card-footer>
    </nb-card>
  `,
})
export class DialogConfirmationPromptComponent {
  constructor(protected dialogRef: NbDialogRef<DialogConfirmationPromptComponent>) {
  }

  content1: any
  
  cancel() {
    this.dialogRef.close();
  }

  submit(name) {
    this.dialogRef.close(name);
  }
}