import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { User } from '../models/user.model'
import { Observable } from 'rxjs'
import 'rxjs/Rx'
import { ServerResponse } from '../models/server-reponse.model'
import { CookieService } from 'ngx-cookie-service'
import { Message } from '@angular/compiler/src/i18n/i18n_ast'

const BroadcastEnpoint = 'api/v2/broadcasts/'
const BroadcastUserEnpoint = 'api/v2/broadcasts/students/'
const NotificationEnpoint = 'api/v2/notifications/'
const TotalUnreadNoti = 'api/v2/notifications/{username}/count?status=sent&status=failed'

@Injectable()
export class NotificationService {
    constructor(private http: HttpClient, private cookieService: CookieService) { }

    getHttpHeaders() {

        return new HttpHeaders({
            'Authorization': 'Bearer ' + this.cookieService.get('access_token'),
            'Content-Type': 'application/json',
        });
    }
    // Student broadcast - who will receive notifications
    getAllImportedStudentsInBroadcast(): Observable<ServerResponse<object[]>> {
        return this.http.get(BroadcastUserEnpoint, {
            params: {
                sort: 'createdAt',
                order: 'desc',
            },
            headers: this.getHttpHeaders(),
        })
            .map(response => response as ServerResponse<object[]> || null);
    }

    importStudentInBroadCast(formData: FormData): Observable<ServerResponse<object[]>> {
        return this.http.post(BroadcastUserEnpoint, formData, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token'),
            }),
        })
            .map(response => response as ServerResponse<object[]> || null);
    }

    exportBroadcastStudents(): Observable<any> {
        return this.http.get(BroadcastUserEnpoint + 'export', {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            }),
        })
            .map(response => response as any || null);
    }

    exportBroadcastNotificationDetail(broadcastId: string): Observable<any> {
        return this.http.get(NotificationEnpoint + 'export/' + `${broadcastId}`, {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            }),
        })
            .map(response => response as any || null);
    }
    deleteStudentInBroadCast(id: string): Observable<ServerResponse<object[]>> {
        return this.http.delete(BroadcastUserEnpoint + `${id}`, {
            headers: this.getHttpHeaders(),
        }).map(response => response as ServerResponse<object[]> || null);
    }

    deleteAllStudentInBroadCast(): Observable<ServerResponse<object[]>> {
        return this.http.delete(BroadcastUserEnpoint, {
            headers: this.getHttpHeaders(),
        }).map(response => response as ServerResponse<object[]> || null);
    }

    // Broadcast
    sendBroadcastNotification(broadcast: object): Observable<ServerResponse<object[]>> {
        return this.http.post(BroadcastEnpoint, broadcast, {
            headers: this.getHttpHeaders(),
        })
            .map(response => response as ServerResponse<object[]> || null);
    }

    getAllBroadcasts(): Observable<ServerResponse<object[]>> {
        return this.http.get(BroadcastEnpoint, {
            params: {
                sort: 'createdAt',
                order: 'desc'
            },
            headers: this.getHttpHeaders()
        })
            .map(response => response as ServerResponse<object[]> || null);
    }

    // Broadcast
    getAllNotificationsByBroadcastId(broadcastId: number): Observable<ServerResponse<object[]>> {
        return this.http.get(NotificationEnpoint, {
            params: {
                sort: 'createdAt',
                order: 'desc',
                broadcastId: broadcastId.toString(),
            },
            headers: this.getHttpHeaders(),
        })
            .map(response => response as ServerResponse<object[]> || null);
    }

    getBroadcastContent(broadcastId: string) {
      return this.http.get(BroadcastEnpoint + broadcastId, {
        headers: this.getHttpHeaders(),
    })
        .map(response => response as ServerResponse<object> || null);
    }

    getNotificationList(userId: string) {
      return this.http.get(NotificationEnpoint + userId, {
        params: {
            sort: 'createdAt',
            order: 'desc',
        },
        headers: this.getHttpHeaders(),
    })
        .map(response => response as ServerResponse<object[]> || null);
    }

    getTotalUnreadNotification(userId: string) {
      return this.http.get(TotalUnreadNoti.replace('{username}', userId), {
        headers: this.getHttpHeaders(),
    })
        .map(response => response as ServerResponse<number> || null);
    }

    updateNotificationState(nid: string, ob: object) {
      return this.http.put(NotificationEnpoint + nid, ob, {
        headers: this.getHttpHeaders(),
      }).map(response => response as ServerResponse<any> || null);
    }

    updateStateOfAllNotifications(userId: string, action: string, isBroadcast: string) {
      return this.http.put(`${NotificationEnpoint}${userId}/${action}?broadcast=${isBroadcast}`, {} , {
        headers: this.getHttpHeaders(),
      }).map(response => response as ServerResponse<any> || null);
    }
}


