import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { User } from '../models/user.model'
import { Observable } from 'rxjs'
import 'rxjs/Rx'
import { ServerResponse } from '../models/server-reponse.model'
import { CookieService } from 'ngx-cookie-service'

const ApiEndpoint = '/api/v2/patches'
const PimApiEndpoint = '/api/v2/patches/pim'
const TrmApiEndpoint = '/api/v2/patches/trm'
const WtiApiEndpoint = '/api/v2/patches/wti'

@Injectable()
export class PatchService {
    constructor(private http: HttpClient, private cookieService: CookieService) { }

    getHttpHeaders() {

        return new HttpHeaders({
            'Authorization': 'Bearer ' + this.cookieService.get('access_token'),
            'Content-Type': 'application/json',
            'accept': '*/*'
        })
    }

    getAllImportedStudents(params?: HttpParams): Observable<ServerResponse<object[]>> {
        if (!params) params = new HttpParams()
        params.set('sort', 'createdAt')
        params.set('order', 'desc')
        params.set("limit", '99999')
        return this.http.get(ApiEndpoint, {
            params: params,
            headers: this.getHttpHeaders(),
        })

            .map(response => response as ServerResponse<object[]> || null)
    }
    updateStudent(username: string, ob: object): Observable<ServerResponse<any>> {
        return this.http.put(`${ApiEndpoint}/${username}`, ob, {
          headers: this.getHttpHeaders()
        }).map(response => response as ServerResponse<any> || null);
    }

    deleteStudent(username: string): Observable<ServerResponse<User>> {
        return this.http.delete(ApiEndpoint + `/${username}`, {
            headers: this.getHttpHeaders()
        })
        .map(response => response as ServerResponse<User> || null)
    }

    importStudent(formData: FormData): Observable<ServerResponse<object[]>> {
        return this.http.post(ApiEndpoint, formData, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            })
        })
            .map(response => response as ServerResponse<object[]> || null)
    }


    updatePim(number, hideCommunity): Observable<ServerResponse<object[]>> {
        return this.http.put(PimApiEndpoint, {
            number: number,
            hideCommunity: hideCommunity
        }, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            })
        })
            .map(response => response as ServerResponse<object[]> || null)
    }
  getPimByNumber(number): Observable<ServerResponse<object[]>> {
    return this.http.put(PimApiEndpoint + `/${number}`, {
      headers: this.getHttpHeaders()
    })
      .map(response => response as ServerResponse<object[]> || null)
  }

    getAllPims(params?: HttpParams): Observable<ServerResponse<object[]>> {
        if (!params) params = new HttpParams()
        params.set('sort', 'createdAt')
        params.set('order', 'desc')
        params.set("limit", '99999')
        return this.http.get(PimApiEndpoint, {
            params: params,
          headers: this.getHttpHeaders(),
        })
            .map(response => response as ServerResponse<object[]> || null)
    }

  createPim(formData: FormData): Observable<ServerResponse<object[]>> {
    return this.http
      .post(PimApiEndpoint, formData, {
        headers: this.getHttpHeaders(),
      })
      .map((response) => (response as ServerResponse<object[]>) || null);
  }
  deletePim(number): Observable<ServerResponse<object[]>> {
    const options = {
      headers: this.getHttpHeaders(),
      body: { number: number }, // Put the number in the request body
    };

    return this.http
      .delete(PimApiEndpoint, options)
      .map((response) => (response as ServerResponse<object[]>) || null);
  }

    updateWti(number, hideCommunity): Observable<ServerResponse<object[]>> {
        return this.http.put(WtiApiEndpoint, {
            number: number,
            hideCommunity: hideCommunity
        }, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            })
        })
            .map(response => response as ServerResponse<object[]> || null)
    }

    getAllWtis(params?: HttpParams): Observable<ServerResponse<object[]>> {
        if (!params) params = new HttpParams()
        params.set('sort', 'createdAt')
        params.set('order', 'desc')
        params.set("limit", '99999')
        return this.http.get(WtiApiEndpoint, {
            params: params,
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            })
        })
            .map(response => response as ServerResponse<object[]> || null)
    }

    updateTrm(number, hideCommunity): Observable<ServerResponse<object[]>> {
        return this.http.put(TrmApiEndpoint, {
            number: number,
            hideCommunity: hideCommunity
        }, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            })
        })
            .map(response => response as ServerResponse<object[]> || null)
    }

    getAllTrms(params?: HttpParams): Observable<ServerResponse<object[]>> {
        if (!params) params = new HttpParams()
        params.set('sort', 'createdAt')
        params.set('order', 'desc')
        params.set('limit', '99999')
        return this.http.get(TrmApiEndpoint, {
            params: params,
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            })
        })
            .map(response => response as ServerResponse<object[]> || null)
    }
}


