import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbDummyAuthStrategy, NbPasswordAuthStrategy, NbAuthJWTToken, NbAuthJWTInterceptor, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { AnalyticsService } from './utils';
import { UserData } from './data/users';
import { UserService } from './mock/users.service';
import { MockDataModule } from './mock/mock-data.module';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpRequest } from '@angular/common/http';


const socialLinks = [
  {
    url: 'https://github.com/akveo/nebular',
    target: '_blank',
    icon: 'github',
  },
  {
    url: 'https://www.facebook.com/akveo/',
    target: '_blank',
    icon: 'facebook',
  },
  {
    url: 'https://twitter.com/akveo_inc',
    target: '_blank',
    icon: 'twitter',
  },
];

const DATA_SERVICES = [
  { provide: UserData, useClass: UserService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

const formSetting: any = {
  redirectDelay: 0, // delay before redirect after a successful login, while success message is shown to the user
  strategy: 'username',  // strategy id key.
  rememberMe: false,   // whether to show or not the `rememberMe` checkbox
  showMessages: {     // show/not show success/error messages
    success: true,
    error: true,
  }
}

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'username',
        baseEndpoint: '/api/v2/auth',
        token: {
          class: NbAuthJWTToken,
          key: 'result.access_token',
        },
        login: {
          endpoint: '/login',
          redirect: {
            success: '/home',
            failure: null, // stay on the same page
          },
        },
        logout: {
          redirect: {
            success: '/',
            failure: '/',
          }
        }
      }),
    ], forms: {
      login: formSetting
    },
  }).providers,

  // NbSecurityModule.forRoot({
  //   // accessControl: {
  //   //   student: {
  //   //     // no permissions
  //   //   },
  //   //   banker: {
  //   //     view: ['aip-menu', 'user', 'aip-student'],
  //   //     edit: ['aip-student', 'conversation']
  //   //   },
  //   //   admin: {
  //   //     view: ['*'],
  //   //     create: '*',
  //   //     edit: ['aip-student'],
  //   //     remove: '*',
  //   //     export: '*  '
  //   //   }
  //   // },
  // }).providers,

  // {
  //   provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  // },
  AnalyticsService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
