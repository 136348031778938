import { Component, OnInit, Output, Input } from "@angular/core";
import {
  NbCalendarRange,
  NbDateService,
  NbDialogRef,
  NbToastrService,
  NbToastRef,
} from "@nebular/theme";
import { Subject } from "rxjs";
import { PatchService } from "../../../services/patch.service";
import { ReportService } from "../../../services/report.service";
import { HttpParams } from "@angular/common/http";
import { User, Bank } from "../../../models/user.model";
import { UserService } from "../../../services/user.service";

@Component({
  selector: "pim-select-modal",
  templateUrl: "./pim-select-modal.component.html",
  styleUrls: ["./pim-select-modal.component.scss"],
})
export class PimSelecModalComponent implements OnInit {
  toastRef: NbToastRef;
  fromValue: string;
  toValue: string;

  from: string;
  to: string;

  pimList: object[];
  wtiList: object[];
  trmList: object[];
  batchList: object[];
  bankerList: object[];
  selectedPim: number;
  selectedStatus: string;
  selectedBanker: number;
  loadingExport: boolean = false;
  isDateFiltered: boolean = true;
  isExported: boolean = true;
  type: string;

  title: string;

  constructor(
    private userService: UserService,
    public ref: NbDialogRef<PimSelecModalComponent>,
    private patchService: PatchService,
    private toastrService: NbToastrService,
    private reportService: ReportService
  ) {}

  ngOnInit() {
    if (this.fromValue != "") {
      this.from = this.fromValue;
    }
    if (this.toValue != "") {
      this.to = this.toValue;
    }
    let httpParams = new HttpParams()
      .set("sort", "number")
      .set("order", "desc");
    if (this.title == "PIM") {
      if (localStorage.getItem("PIMList") == "") {
        this.patchService.getAllPims(httpParams).subscribe(
          (data) => {
            if (data) {
              var tempPIMList = [];
              var allItem = {};
              allItem["number"] = "All";
              allItem["isSelected"] = false;
              tempPIMList.push(allItem);
              for (let index = 0; index < data.result.length; index++) {
                var element = data.result[index];
                element["isSelected"] = false;
                tempPIMList.push(element);
              }
              this.pimList = tempPIMList;
              localStorage.setItem("PIMList", JSON.stringify(this.pimList));
              this.batchList = this.pimList
            }
          },
          (error) => {
            if (error.status != 401 && error.status != 403) {
              this.toastRef = this.toastrService.show(
                "Server is not available",
                "Error",
                {
                  status: "danger",
                }
              );
            }
          }
        );
      } else {
        this.pimList = JSON.parse(localStorage.getItem("PIMList"));
      }
      this.batchList = this.pimList
    } else if (this.title == "WTI/PPP") {
      if (localStorage.getItem("WTIList") == "") {
        this.patchService.getAllWtis(httpParams).subscribe(
          (data) => {
            if (data) {
              var tempWTIList = [];
              var allItem = {};
              allItem["number"] = "All";
              allItem["isSelected"] = false;
              tempWTIList.push(allItem);
              for (let index = 0; index < data.result.length; index++) {
                var element = data.result[index];
                element["isSelected"] = false;
                tempWTIList.push(element);
              }
              this.wtiList = tempWTIList;
              localStorage.setItem("WTIList", JSON.stringify(this.wtiList));
              this.batchList = this.wtiList
            }
          },
          (error) => {
            if (error.status != 401 && error.status != 403) {
              this.toastRef = this.toastrService.show(
                "Server is not available",
                "Error",
                {
                  status: "danger",
                }
              );
            }
          }
        );
      } else {
        this.wtiList = JSON.parse(localStorage.getItem("WTIList"));
      }
      this.batchList = this.wtiList
    } else if (this.title == "TRM") {
      if (localStorage.getItem("TRMList") == "") {
        this.patchService.getAllTrms(httpParams).subscribe(
          (data) => {
            if (data) {
              var tempTRMList = [];
              var allItem = {};
              allItem["number"] = "All";
              allItem["isSelected"] = false;
              tempTRMList.push(allItem);
              for (let index = 0; index < data.result.length; index++) {
                var element = data.result[index];
                element["isSelected"] = false;
                tempTRMList.push(element);
              }
              this.trmList = tempTRMList;
              localStorage.setItem("TRMList", JSON.stringify(this.trmList));
              this.batchList = this.trmList
            }
          },
          (error) => {
            if (error.status != 401 && error.status != 403) {
              this.toastRef = this.toastrService.show(
                "Server is not available",
                "Error",
                {
                  status: "danger",
                }
              );
            }
          }
        );
      } else {
        this.trmList = JSON.parse(localStorage.getItem("TRMList"));
      }
      this.batchList = this.trmList
    }
  }

  onValueChange(checked: boolean, number) {
    if (this.title == "PIM") {
      if (number == "All") {
        for (let index = 0; index < this.pimList.length; index++) {
          this.pimList[index]["isSelected"] = checked;
        }
      } else {
        this.pimList[0]["isSelected"] = false;
        this.pimList[this.pimList.findIndex(e => e["number"] === number)]["isSelected"] = checked;
      }
      localStorage.setItem("PIMList", JSON.stringify(this.pimList));
    } else if (this.title == "TRM") {
      if (number == "All") {
        for (let index = 0; index < this.trmList.length; index++) {
          this.trmList[index]["isSelected"] = checked;
        }
      } else {
        this.trmList[0]["isSelected"] = false;
        this.trmList[this.trmList.length - number]["isSelected"] = checked;
      }
      localStorage.setItem("TRMList", JSON.stringify(this.trmList));
    } else if (this.title == "WTI/PPP") {
      if (number == "All") {
        for (let index = 0; index < this.wtiList.length; index++) {
          this.wtiList[index]["isSelected"] = checked;
        }
      } else {
        this.wtiList[0]["isSelected"] = false;
        this.wtiList[this.wtiList.length - number]["isSelected"] = checked;
      }
      localStorage.setItem("WTIList", JSON.stringify(this.wtiList));
    }
  }
  dismiss() {
    this.ref.close(this.from);
  }
  export() {
    if (this.title == "PIM") {
      var pimString = "";
      for (let index = 0; index < this.pimList.length; index++) {
        var element = this.pimList[index];
        if (element["isSelected"]) {
          if (element["number"] == "None") {
            pimString = pimString + "0,";
          } else {
            pimString = pimString + element["number"] + ",";
          }
        }
      }
      this.userService.getUsersByPIM(pimString).subscribe(
        (data) => {
          this.toastRef = this.toastrService.show(
            "Successfully load Students.",
            "Success",
            {
              status: "success",
              duration: 10000,
            }
          );
          this.dismiss();
        },
        (error) => {
          this.loadingExport = false;
          this.toastRef = this.toastrService.show(
            "Failed to load Students",
            error.statusText,
            {
              status: "danger",
              duration: 10000,
            }
          );
        }
      );
    } else if (this.title == "WTI/PPP") {
      var wtiString = "";
      for (let index = 0; index < this.wtiList.length; index++) {
        var element = this.wtiList[index];
        if (element["isSelected"]) {
          if (element["number"] == "None") {
            wtiString = wtiString + "0,";
          } else {
            wtiString = wtiString + element["number"] + ",";
          }
        }
      }
      this.userService.getUsersByWTI(wtiString).subscribe(
        (data) => {
          this.toastRef = this.toastrService.show(
            "Successfully load Students.",
            "Success",
            {
              status: "success",
              duration: 10000,
            }
          );
          this.dismiss();
        },
        (error) => {
          this.loadingExport = false;
          this.toastRef = this.toastrService.show(
            "Failed to load Students",
            error.statusText,
            {
              status: "danger",
              duration: 10000,
            }
          );
        }
      );
    } else if (this.title == "TRM") {
      var trmString = "";
      for (let index = 0; index < this.trmList.length; index++) {
        var element = this.trmList[index];
        if (element["isSelected"]) {
          if (element["number"] == "None") {
            trmString = trmString + "0,";
          } else {
            trmString = trmString + element["number"] + ",";
          }
        }
      }
      this.userService.getUsersByTRM(trmString).subscribe(
        (data) => {
          this.toastRef = this.toastrService.show(
            "Successfully load Students.",
            "Success",
            {
              status: "success",
              duration: 10000,
            }
          );
          this.dismiss();
        },
        (error) => {
          this.loadingExport = false;
          this.toastRef = this.toastrService.show(
            "Failed to load Students",
            error.statusText,
            {
              status: "danger",
              duration: 10000,
            }
          );
        }
      );
    }
  }
}
