import {Http, ResponseContentType, Headers } from '@angular/http';
import {Injectable} from '@angular/core';
import { CookieService } from 'ngx-cookie-service'

const ApiFiles = 'api/v2/files/{fileId}/{userId}/download';

@Injectable({ providedIn: 'root' })
export class FileService {

  constructor(private http: Http, private cookieService: CookieService) {}

   downloadFile(fileId: string, userId: string, name: string) {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + this.cookieService.get('access_token'));
    headers.append('accept', '*/*');
    const url = ApiFiles.replace('{fileId}', fileId).replace('{userId}', userId);
    return this.http
      .get(url, {
        responseType: ResponseContentType.Blob,
        headers: headers,
      })
      .map(res => {
        return {
          filename: name,
          data: res.blob()
        };
      })
      .subscribe(res => {
          console.log('start download:',res);
          var url = window.URL.createObjectURL(res.data);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        }, error => {
          console.log('download error:', JSON.stringify(error));
        }, () => {
          console.log('Completed file download.')
        });
  }
}