import {Injectable} from '@angular/core'
import {CanActivate, Router} from '@angular/router'
import { CookieService } from 'ngx-cookie-service'

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private cookieService: CookieService) {}

  canActivate(): boolean {
    const token = this.cookieService.get('authenticated')
    if (!token) {
      this.router.navigate(['login'])
      return false
    }
    return true
  }
}