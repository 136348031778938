import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterDateRangeAndExportModalComponent } from './filter-date-range-and-export-modal.component';
import { NbCardModule, NbCalendarRangeModule, NbButtonModule, NbDialogService, NbDatepickerModule, NbInputModule, NbSelectModule, NbSpinnerModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { NbDateFnsDateModule } from '@nebular/date-fns';



@NgModule({
  declarations: [FilterDateRangeAndExportModalComponent],
  imports: [
    CommonModule,
    NbCardModule,
    // NbCalendarRangeModule,
    NbButtonModule,
    NbDatepickerModule,
    NbInputModule,
    FormsModule,
    NbDateFnsDateModule,
    NbSelectModule,
    NbSpinnerModule
  ],
  
})
export class FilterDateRangeAndExportModalModule { }
