import { Component, OnDestroy, OnInit, ViewChild, TemplateRef, ElementRef, HostListener } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbToastrService, NbToastRef, NbPopoverDirective } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../../../services/user.service';
import { NotificationService } from '../../../services/notification.service';
import { Router } from '@angular/router';
import { MessagingService } from "../../../services/messaging.service";

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {


  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  message;
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  // { title: 'Profile' , link: '/profile'}
  userMenu = [{ title: 'Log out', link: '/auth/logout' }];

  toastRef: NbToastRef;
  profileImageUrl: String = "https://quintenzirkel-meffert.de/wp-content/uploads/2018/09/platzhalter-3.png";
  profileImage: any;

  dataConfig: Array<any> = [];

  totalUnreadNotification: number;
  notificationList: object[];
  broadcastList: object[];
  unreadNotification: number;
  unreadBroadcast: number;
  currentTab: number;

  @ViewChild(NbPopoverDirective, { static: false }) popover: NbPopoverDirective;
  //@ViewChild('notificationViewer', { read: TemplateRef, static: false }) notificationPopover: NbPopoverDirective;
  //@ViewChild('notificationViewer', { read: TemplateRef, static: false }) templateTabs: TemplateRef<any>;


  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserService,
    protected cookieService: CookieService,
    private breakpointService: NbMediaBreakpointsService,
    private toastrService: NbToastrService,
    private notificationService: NotificationService,
    private router: Router,
    private eRef: ElementRef,
    private messagingService: MessagingService) {
    this.totalUnreadNotification = 0;
    this.notificationList = [];
    this.broadcastList = [];
    this.unreadBroadcast = 0;
    this.unreadNotification = 0;
    this.currentTab = 0;
  }

  private _getUserInfo(): void {
    this.userService.getUserByUsername(this.cookieService.get("phone")).subscribe(data => {
      if (data) {
        this.user = data.result;
        this._getProfileImage();
      }
    }, error => {
      if (error.status != 401 && error.status != 403) {
        this.toastRef = this.toastrService.show('Server is not available', "Error", {
          status: "danger"
        })
      }
    })
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)
      && (event.target as Element).closest('.header-notification-viewer') == null
      && (event.target as Element).closest('notification-card') == null) {
      this.popover.hide();
    }
  }

  ngOnInit() {
    this.userService.getConfiguration().subscribe(data => {
      if (data) {
        this.dataConfig = data.result;
      }
    }, error => { });
    this.currentTheme = this.themeService.currentTheme;
    this.getTotalUnreadNoti()
    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => {
    //     console.log(users);
    //     this.user = users.nick
    //   });

    this.updateNotification()
    this._getUserInfo();


    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    const username = this.cookieService.get("phone");
    this.messagingService.requestPermission(username)
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
    this.message.subscribe(
      (payload) => {
        if (payload) {
          this.newNotification()
          this.getTotalUnreadNoti()
          this.updateNotification()
        }
      })
  }
  getTotalUnreadNoti() {
    this.notificationService.getTotalUnreadNotification(this.cookieService.get("phone")).subscribe(data => {
      this.totalUnreadNotification = data.result
    });
  }
  updateNotification() {
    this.notificationList = []
    this.notificationService.getNotificationList(this.cookieService.get("phone")).subscribe(data => {
      for (let i = 0; i < data.result.length; i++) {
        if ((data.result[i]['type'] as string).toLowerCase().includes('broadcast')) {
          // this.broadcastList.push(data.result[i]);

          // if ((data.result[i]['status'] as string).toLowerCase() !== 'read') {
          //   this.unreadBroadcast++;
          // }
        } else {
          this.notificationList.push(data.result[i]);
        }
      }
    });
  }
  newNotification() {
    this.toastRef = this.toastrService.show('New notification recieve', "Info", {
      status: "success"
    })
  }
  ngOnDestroy() {
    this.messagingService.ngOnDestroy()
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.popover.hide();
    this.router.navigate(['/aip']);
  }

  closeNotificationViewer() {
    document.querySelector('.notification-viewer').classList.remove('show-notification');
  }

  notificationOnClicked() {
    document.querySelector('.notification-viewer').classList.toggle('show-notification');
  }

  private _getProfileImage(): void {
    if (!this.user.image) return
    this.userService.getImage(this.user.image).subscribe(data => {
      this._createImageFromBlob(data);
    }, error => {
      // console.log('Cannot get profile image - use dafault image: ' + error);
    });
  }

  private _createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.profileImage = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  profileIsClicked() {
    this.popover.hide();
    this.router.navigate(['/profile']);
  }

  settingIsClicked() {
    this.popover.hide();
  }

  markAllNotificationsAsRead() {
    let isBroadcast = 'false';
    if (this.currentTab === 1) {
      isBroadcast = 'true';
    }
    this.notificationService.updateStateOfAllNotifications(this.cookieService.get('phone'), 'read', isBroadcast).subscribe(response => {
      if (response.error) {
        alert(response.error);
      } else {
        if (this.currentTab === 0) {
          for (let i = 0; i < this.notificationList.length; i++) {
            this.notificationList[i]['status'] = 'read';
          }
          this.unreadNotification = 0;
          this.totalUnreadNotification = this.unreadBroadcast;
        } else {
          for (let i = 0; i < this.broadcastList.length; i++) {
            this.broadcastList[i]['status'] = 'read';
          }
          this.unreadBroadcast = 0;
          this.totalUnreadNotification = this.unreadNotification;
        }
      }
    });
  }

  deleteAllNotifcations() {
    let isBroadcast = 'false';
    if (this.currentTab === 1) {
      isBroadcast = 'true';
    }
    this.notificationService.updateStateOfAllNotifications(this.cookieService.get('phone'), 'deleted', isBroadcast)
      .subscribe(response => {
        if (response.error) {
          alert(response.error);
        } else {
          if (this.currentTab === 0) {
            this.notificationList = [];
            this.unreadNotification = 0;
            this.totalUnreadNotification = this.unreadBroadcast;
          } else {
            this.broadcastList = [];
            this.unreadBroadcast = 0;
            this.totalUnreadNotification = this.unreadNotification;
          }
        }
      });
  }

  aNotificationOnClicked($event: Event, notification: object) {
    if (($event.target as Element).classList.contains('trash-btn')) {
      return;
    }
    if (notification['status'] !== 'read') {
      this.notificationService.updateNotificationState(notification['notificationId'], {
        'status': 'read'
      }).subscribe(data => {
        if (data.error) {
          alert(data.error);
        } else {
          if (this.currentTab === 0) {
            for (let i = 0; i < this.notificationList.length; i++) {
              if (this.notificationList[i]['notificationId'] === data.result['notificationId']) {
                this.notificationList[i]['status'] = 'read';
                this.unreadNotification--;
                break;
              }
            }
          } else {
            for (let i = 0; i < this.broadcastList.length; i++) {
              if (this.broadcastList[i]['notificationId'] === data.result['notificationId']) {
                this.broadcastList[i]['status'] = 'read';
                this.unreadBroadcast--;
                break;
              }
            }
          }
          this.totalUnreadNotification--;
        }
      });
    } else {
      if (this.currentTab === 0) {
        this.router.navigate([`/aip/details`], {
          queryParams: {
            'id': notification['aipId'],
            'username': notification['student']['username'],
          },
        });
      } else {
        // write a page for this.
      }
    }
  }

  removeNotification($event: Event) {
    if (this.currentTab === 0) {
      let index = -1;
      for (let i = 0; i < this.notificationList.length; i++) {
        if (this.notificationList[i]['notificationId'] === $event) {
          if (this.notificationList[i]['status'] !== 'read') {
            this.unreadNotification--;
            this.totalUnreadNotification--;
          }
          index = i;
          break;
        }
      }
      if (index !== -1) {
        this.notificationList[index]['status'] = 'deleted';
      }
    } else {
      let index = -1;
      for (let i = 0; i < this.broadcastList.length; i++) {
        if (this.broadcastList[i]['notificationId'] === $event) {
          if (this.broadcastList[i]['status'] !== 'read') {
            this.unreadBroadcast--;
            this.totalUnreadNotification--;
          }
          index = i;
          break;
        }
      }
      if (index !== -1) {
        this.broadcastList[index]['status'] = 'deleted';
      }
    }
  }

  openNotificationPopup() {
    this.popover.toggle();
  }


  onChangeTab($event: any) {
    if ($event.tabTitle.toLowerCase() === 'notifications') {
      this.currentTab = 0;
    } else {
      this.currentTab = 1;
    }
  }
}
