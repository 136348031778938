import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  //selector: 'image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements OnInit {

  @Input() file: any;

  constructor(public ref: NbDialogRef<ImageDialogComponent>) { }

  ngOnInit() {
  }
    dismiss() {
    this.ref.close();
  }
}
