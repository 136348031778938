import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PimSelecModalComponent } from './pim-select-modal.component';
import { NbCardModule, NbCalendarRangeModule, NbCheckboxModule, NbButtonModule, NbDialogService, NbDatepickerModule, NbInputModule, NbSelectModule, NbSpinnerModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { NbDateFnsDateModule } from '@nebular/date-fns';



@NgModule({
  declarations: [PimSelecModalComponent],
  imports: [
    CommonModule,
    NbCardModule,
    // NbCalendarRangeModule,
    NbButtonModule,
    NbDatepickerModule,
    NbInputModule,
    FormsModule,
    NbDateFnsDateModule,
    NbSelectModule,
    NbSpinnerModule,
    NbCheckboxModule
  ],
  
})
export class PimSelecModalModule { }
