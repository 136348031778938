
import { Injectable, OnInit } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, } from '@angular/common/http'
import { User, AIP } from '../models/user.model'
import { Observable } from 'rxjs'
import 'rxjs/Rx'
import { ServerResponse } from '../models/server-reponse.model'
import { CookieService } from 'ngx-cookie-service'
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth'
import { map } from 'rxjs/operators'

const ApiEndpoint = '/api/v2/aips';
const ApiImages = '/api/v2/files/images/';
const ApiFiles = 'api/v2/files/{fileId}/{userId}/download';
const ApiFilesBy = 'api/v2/files/{fileId}/{userId}';

@Injectable()
export class AipService implements OnInit {
  ngOnInit(): void {
    this.headers = this.getHttpHeaders();
  }
  role = 'student';
  username = '';
  headers: any;

  constructor(private http: HttpClient, private cookieService: CookieService, private authService: NbAuthService) {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          this.role = token.getPayload()['role']; // here we receive a payload from the token and assigns it to our `user` variable
          this.username = token.getPayload()['username'];
        }
      });
  }

  getHttpHeaders() {

    return new HttpHeaders({
      'Authorization': 'Bearer ' + this.cookieService.get('access_token'),
      'Content-Type': 'application/json',
      'accept': '*/*'
    })
  }

  public getAllAIP(params?: HttpParams): Observable<ServerResponse<any[]>> {
    let endpoint = ApiEndpoint + '/'
    switch (this.role) {
      case 'banker':
        endpoint += this.role + '/' + this.username
        break;
      default:
        break;
    }
    return this.http.get(endpoint, {
      headers: this.getHttpHeaders(),
      params: params
    })
      .map(response => response as ServerResponse<any[]> || null)
  }

  public getImage(imageUrl: string): Observable<Blob> {
    return this.http.get(`${ApiImages}${imageUrl}`, {
      headers: this.getHttpHeaders(),
      responseType: 'blob',
    });
  }

  public getFile(fileId: string, userId: string) {
    const url = ApiFiles.replace('{fileId}', fileId).replace('{userId}', userId);
    return this.http.get(url, {
      headers: this.getHttpHeaders(),
      responseType: 'blob',
    });
  }
  public getFiles(fileId: string, userId: string) {
    const url = ApiFilesBy.replace('{fileId}', fileId).replace('{userId}', userId);
    return this.http.get(url, {
      headers: this.getHttpHeaders()
    }).map(response => response as ServerResponse<object[]> || null);;
  }

  public getAIPById(id: string): Observable<ServerResponse<AIP[]>> {
    return this.http.get(`${ApiEndpoint}/${id}`, {
      headers: this.getHttpHeaders(),
    })
      .map(response => response as ServerResponse<AIP[]> || null)
  }

  public updateAIP(id: string, ob: object): Observable<ServerResponse<any>> {
    return this.http.put(`${ApiEndpoint}/${id}`, ob, {
      headers: this.getHttpHeaders()
    }).map(response => response as ServerResponse<any> || null)
  }

  public updateAIPStatus(id: string, ob: object): Observable<ServerResponse<any>> {
    return this.http.put(`${ApiEndpoint}/${id}/status`, ob, {
      headers: this.getHttpHeaders(),
    }).map(response => response as ServerResponse<any> || null)
  }

  public addNewMessage(id: string, ob: object): Observable<ServerResponse<any>> {
    return this.http.post(`${ApiEndpoint}/${id}/conversations`, ob, {
      headers: this.getHttpHeaders(),
    }).map(response => response as ServerResponse<any> || null)
  }
}




