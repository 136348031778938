import { Component, OnInit, Output, Input } from '@angular/core';
import { NbCalendarRange, NbDateService, NbDialogRef, NbToastrService, NbToastRef } from '@nebular/theme';
import { Subject } from 'rxjs';
import { PatchService } from '../../../services/patch.service';
import { ReportService } from '../../../services/report.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'filter-date-range-and-export-modal',
  templateUrl: './filter-date-range-and-export-modal.component.html',
  styleUrls: ['./filter-date-range-and-export-modal.component.scss']
})
export class FilterDateRangeAndExportModalComponent implements OnInit {

  toastRef: NbToastRef
  fromValue: string;
  toValue: string;

  from: string;
  to: string;

  pimList: object[]
  selectedPim: number
  wtiList: object[]
  selectedWti: number
  trmList: object[]
  selectedTrm: number
  selectedIc: string

  loadingExport: boolean = false;
  isDateFiltered: boolean = true;
  isExported: boolean = true;
  type: string

  title: string

  constructor(public ref: NbDialogRef<FilterDateRangeAndExportModalComponent>, private patchService: PatchService, private toastrService: NbToastrService, private reportService: ReportService) {


  }

  ngOnInit() {
    if (this.fromValue != "") {
      this.from = this.fromValue;
    }
    if (this.toValue != "") {
      this.to = this.toValue
    }
    let httpParams = new HttpParams().set('sort','number').set('order', 'desc')

    this.patchService.getAllPims(httpParams).subscribe(data => {
      if (data) {
        this.pimList = data.result;
      }
    }, error => {
      if (error.status != 401 && error.status != 403) {
        this.toastRef = this.toastrService.show('Server is not available', "Error", {
          status: "danger"
        })
      }
    });
    this.patchService.getAllPims(httpParams).subscribe(data => {
      if (data) {
        this.pimList = data.result;
      }
    }, error => {
      if (error.status != 401 && error.status != 403) {
        this.toastRef = this.toastrService.show('Server is not available', "Error", {
          status: "danger"
        })
      }
    });
    this.patchService.getAllTrms(httpParams).subscribe(data => {
      if (data) {
        this.trmList = data.result;
      }
    }, error => {
      if (error.status != 401 && error.status != 403) {
        this.toastRef = this.toastrService.show('Server is not available', "Error", {
          status: "danger"
        })
      }
    });
    this.patchService.getAllWtis(httpParams).subscribe(data => {
      if (data) {
        this.wtiList = data.result;
      }
    }, error => {
      if (error.status != 401 && error.status != 403) {
        this.toastRef = this.toastrService.show('Server is not available', "Error", {
          status: "danger"
        })
      }
    });
  }

  private _exportStudents(filter?: any): void {
    this.reportService.exportImportedStudents(filter).subscribe(data => {
      this.loadingExport = false
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const element = document.createElement('a');
      const fileName = 'Student_List_' + this._todayFormat() + '.xlsx'
      element.download = fileName
      element.href = url
      document.body.appendChild(element);
      element.click();
      this.toastRef = this.toastrService.show("Successfully exported imported students.", "Success", {
        status: "success",
        duration: 10000
      });
    }, error => {
      this.loadingExport = false
      this.toastRef = this.toastrService.show('Failed to export imported students.', error.statusText, {
        status: "danger",
        duration: 10000
      })
    })
  }


  private _exportAIPS(filter?: any): void {
    this.reportService.exportAIPs(filter).subscribe(data => {
      this.loadingExport = false
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const element = document.createElement('a');
      const fileName = 'TIQ_App_Report_' + this._todayFormat() + '.xlsx'
      element.download = fileName
      element.href = url
      document.body.appendChild(element);
      element.click();
      this.toastRef = this.toastrService.show("Successfully exported AIPs.", "Success", {
        status: "success",
        duration: 10000
      });
    }, error => {
      this.loadingExport = false
      this.toastRef = this.toastrService.show('Failed to export AIPs', error.statusText, {
        status: "danger",
        duration: 10000
      })
    })
  }

  private _exportRegisteredStudents(filter?: any): void {
    this.reportService.exportRegisteredStudents(filter).subscribe(data => {
      this.loadingExport = false
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const element = document.createElement('a');
      const fileName = 'Student_List_Registed_' + this._todayFormat() + '.xlsx'
      element.download = fileName
      element.href = url
      document.body.appendChild(element);
      element.click();
      this.toastRef = this.toastrService.show("Successfully exported registered students.", "Success", {
        status: "success",
        duration: 10000
      });
    }, error => {
      this.loadingExport = false
      this.toastRef = this.toastrService.show('Failed to export registered students.', error.statusText, {
        status: "danger",
        duration: 10000
      })
    })
  }

  private _todayFormat() {
    const today = new Date()
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    var day = today.getDate();
    var hours = today.getHours();
    var minutes = today.getMinutes();
    var seconds = today.getSeconds();

    return (year + "_" + month + "_" + day + "_" + hours + "_" + minutes + "_" + seconds);
  }

  get today(): Date {
    return new Date();
  }

  // get monthStart(): Date {
  //   return this.dateService.getMonthStart(new Date());
  // }

  // get monthEnd(): Date {
  //   return this.dateService.getMonthEnd(new Date());
  // }

  onPimSelected(number: number) {
    this.selectedPim = number
  }

  onWtiSelected(number: number) {
    this.selectedWti = number
  }
  onTrmSelected(number: number) {
    this.selectedTrm = number
  }
  dismiss() {
    this.ref.close(this.from);
  }



  filter() {
    this.ref.close({
      action: "filter",
      value: {
        start: this.from,
        end: this.to,
        pim: this.selectedPim,
        wti: this.selectedWti,
        trm: this.selectedTrm,
        ic: this.selectedIc
      }
    });
  }

  export() {
    this.loadingExport = true
    switch (this.type) {
      case 'aip':
        this._exportAIPS({
          "from": this.from ? this.from : '',
          "to": this.to ? this.to : '',
          "pim": this.selectedPim ? this.selectedPim : '',
          "wti": this.selectedWti ? this.selectedWti : '',
          "trm": this.selectedTrm ? this.selectedTrm : '',
          "ic": this.selectedIc ? this.selectedIc : '',
          "time_zone": Intl.DateTimeFormat().resolvedOptions().timeZone
        })
        break;
      case 'registeredStudents':
        this._exportRegisteredStudents({
          "from": this.from ? this.from : '',
          "to": this.to ? this.to : '',
          "pim": this.selectedPim ? this.selectedPim : '',
          "wti": this.selectedWti ? this.selectedWti : '',
          "trm": this.selectedTrm ? this.selectedTrm : '',
          "ic": this.selectedIc ? this.selectedIc : '',
          "time_zone": Intl.DateTimeFormat().resolvedOptions().timeZone
        })
        break;
      case 'importedStudents':
        this._exportStudents({
          "from": this.from ? this.from : '',
          "to": this.to ? this.to : '',
          "pim": this.selectedPim ? this.selectedPim : '',
          "wti": this.selectedWti ? this.selectedWti : '',
          "trm": this.selectedTrm ? this.selectedTrm : '',
          "ic": this.selectedIc ? this.selectedIc : '',
          "time_zone": Intl.DateTimeFormat().resolvedOptions().timeZone
        })
        break;
      default:
        break;
    }
  }


}
