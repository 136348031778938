import { Component, OnInit, Output, Input } from '@angular/core';
import { NbCalendarRange, NbDateService, NbDialogRef, NbToastrService, NbToastRef } from '@nebular/theme';
import { Subject } from 'rxjs';
import { PatchService } from '../../../services/patch.service';
import { ReportService } from '../../../services/report.service';
import { HttpParams } from '@angular/common/http';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'subscription-date-modal',
  templateUrl: './subscription-date-modal.component.html',
  styleUrls: ['./subscription-date-modal.component.scss']
})
export class SubscriptionDateModalComponent implements OnInit {

  toastRef: NbToastRef
  subscriptionValue: string;
  subscription: Date;
  subscriptionPicker: Date;
  name: string;
  phone: string;
  lifetime: boolean = false;

  loadingExport: boolean = false;
  isDateFiltered: boolean = true;
  isExported: boolean = true;
  type: string

  title: string

  constructor(public ref: NbDialogRef<SubscriptionDateModalComponent>, private userService: UserService, private toastrService: NbToastrService) {


  }

  ngOnInit() {
    if (this.subscriptionValue == "Lifetime") {
      this.lifetime = true;
    } else if (this.subscriptionValue != "") {
      this.subscription = new Date(this.subscriptionValue);
      this.subscriptionPicker = new Date(this.subscriptionValue);
    }
  }S
  dismiss() {
    this.ref.close(null);
  }
  public update(): void {
    if(!this.lifetime && (this.subscription == undefined)) {
      this.userService.updateUserInfo(this.phone,{
        subscription: ""
      }).subscribe(data => {
        if (data) {
          this.toastRef = this.toastrService.show("Successfully Updated Subscription Date", "Info", {
            status: "success"
          });
          this.ref.close("Lifetime");
        }
      }, error => {
        this.toastRef = this.toastrService.show('Failed to update Subscription Date', "Error", {
          status: "danger"
        });
      });
    } else if(this.lifetime) {
      this.userService.updateUserInfo(this.phone,{
        subscription: "Lifetime"
      }).subscribe(data => {
        if (data) {
          this.toastRef = this.toastrService.show("Successfully Updated Subscription Date", "Info", {
            status: "success"
          });
          this.ref.close("Lifetime");
        }
      }, error => {
        this.toastRef = this.toastrService.show('Failed to update Subscription Date', "Error", {
          status: "danger"
        });
      });
    } else {
    this.userService.updateUserInfo(this.phone,{
      subscription: this.subscription
    }).subscribe(data => {
      if (data) {
        this.toastRef = this.toastrService.show("Successfully Updated Subscription Date", "Info", {
          status: "success"
        });
        this.ref.close(this.subscription);
      }
    }, error => {
      this.toastRef = this.toastrService.show('Failed to update Subscription Date', "Error", {
        status: "danger"
      });
    });
  }
  }
  lifetimeChange(checked) {
    this.lifetime = checked.target.checked; // your variable
  }
  private _todayFormat() {
    const today = new Date()
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    var day = today.getDate();
    var hours = today.getHours();
    var minutes = today.getMinutes();
    var seconds = today.getSeconds();

    return (year + "_" + month + "_" + day + "_" + hours + "_" + minutes + "_" + seconds);
  }

  get today(): Date {
    return new Date();
  }


}
