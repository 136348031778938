import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDeleteUserComponent } from './confirm-delete-user.component';
import { NbCardModule, NbButtonModule } from '@nebular/theme';



@NgModule({
  declarations: [ConfirmDeleteUserComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbButtonModule
  ]
})
export class ConfirmDeleteUserModule { }
