import { Component, OnDestroy, OnInit } from '@angular/core'
import { NbDialogService, NbToastRef, NbToastrService } from '@nebular/theme';
import { DialogConfirmationPromptComponent } from './confimation.component';
import { PatchService } from '../../services/patch.service';
import { HttpParams } from '@angular/common/http';

interface Filter {
  name: string
  role: string
}

@Component({
  selector: 'community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})



export class CommunityComponent implements OnInit {
  pimList: any[]
  pim: {
    pimPactchNumber: number,
    hideCommunity: boolean
  }
  toastRef: NbToastRef
  
  ngOnInit(): void {
    this._loadPimList()
  }

  constructor(private dialogService: NbDialogService, private patchService: PatchService, private toastrService: NbToastrService) {}
checked = false;

_loadPimList = async () => {
  let httpParams = new HttpParams().set('sort','number').set('order', 'desc')

  this.patchService.getAllPims(httpParams).subscribe(data => {
    if (data) {
      this.pimList = data.result;
    }
  }, error => {
    if (error.status != 401 && error.status != 403) {
      this.toastRef = this.toastrService.show('Server is not available', "Error", {
        status: "danger"
      })
    }
  });
}

onValueChange(checked: boolean, number) {
  const simpleText = checked ? ' hide '  : ' unhide '
  const v3Text = checked ? ' hidden.'  : ' unhidden.'
  this.dialogService.open(DialogConfirmationPromptComponent, {
    context: {
      content1: 'Are you sure you want to' + simpleText  + 'community for this Batch?'
    }
  })
      .onClose.subscribe(accepted => {
        if(accepted) {
          this.patchService.updatePim(number, checked).subscribe(data => {
            if (data) {
              this.toastRef = this.toastrService.show("Community is" +  v3Text, "Successfully", {
                status: "success"
              })
              this._loadPimList()
            }
          }, error => {
            if (error.status === 409 || 400) {
              this.toastRef = this.toastrService.show(error.message, error.statusText, {
                status: "danger",
                duration: 10000
              })
            }
            else {
              this.toastRef = this.toastrService.show("Cannot import students. Please check your excel files.", "Error", {
                status: "danger"
              })
            }
            console.log(error)    
          })

        }
        else {
          this._loadPimList() //reload to rollback correct Pim status
        }
      });
}


}
