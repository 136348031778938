import { NgModule } from '@angular/core';
import { NbButtonModule, NbActionsModule, NbCheckboxModule, NbIconModule, NbCardModule, NbDialogModule } from '@nebular/theme';

import { ThemeModule } from '../../@theme/theme.module';
import { CommonModule } from '@angular/common';
import { CommunityComponent } from './community.component';
import { DialogConfirmationPromptComponent } from './confimation.component';

@NgModule({
  imports: [
    CommonModule,
    NbButtonModule,
    NbActionsModule,
    NbCheckboxModule,
    NbIconModule,
    NbCardModule,
    ThemeModule,
    NbDialogModule
  ],
  declarations: [
    CommunityComponent,
    DialogConfirmationPromptComponent 
  ],
})
export class CommunityModule { }
