import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';
import {ServerResponse} from '../models/server-reponse.model';
import {Faststart} from '../models/user.model';
import {map} from 'rxjs/operators';

const FastStartApiPath = '/api/v2/classes';

@Injectable()
export class FastStartService {
    constructor(private http: HttpClient, private cookieService: CookieService) {
    }

    getHttpHeaders() {
        return new HttpHeaders({
            'Authorization': 'Bearer ' + this.cookieService.get('access_token'),
            'Content-Type': 'application/json',
            'accept': '*/*',
        });
    }

    getFastStart(params?: HttpParams): Observable<ServerResponse<Faststart[]>> {
        return this.http.get(
            FastStartApiPath,
            {
                params: params,
                headers: this.getHttpHeaders(),
            },
        ).map(response => response as ServerResponse<Faststart[]>);
    }

    getByID(id: string): Observable<ServerResponse<Faststart>> {
        return this.http.get(`${FastStartApiPath}/${id}`, {
            headers: this.getHttpHeaders(),
        }).map(response => response as ServerResponse<Faststart>);
    }

    updateFastStart(id: string, data: object) {
        return this.http.put(`${FastStartApiPath}/${id}`, data, {
            headers: this.getHttpHeaders(),
        }).pipe(map(response => response as ServerResponse<Faststart>));
    }

    deleteFastStart(id: string): Observable<any> {
        return this.http.delete(`${FastStartApiPath}/${id}`, {
            headers: this.getHttpHeaders(),
        });
    }

    createFastStart(data: object): Observable<ServerResponse<Faststart>> {
        return this.http.post(FastStartApiPath, data, {
            headers: this.getHttpHeaders(),
        }).map(response => response as ServerResponse<Faststart>);
    }
}




