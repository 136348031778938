import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { Router } from '@angular/router'
import { Injectable } from '@angular/core'
import { NbToastrService, NbToastRef } from '@nebular/theme'
import { CookieService } from 'ngx-cookie-service'

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  toastRef: NbToastRef
  constructor(private router: Router, private toastrService: NbToastrService, protected cookieService: CookieService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).do((event: HttpEvent<any>) => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status == 401 || err.status == 403) {
            this.cookieService.delete('access_token');
            this.cookieService.delete('authenticated');
            this.toastRef = this.toastrService.show('You are not authorized to access this resource', "Unauthorized", {
              status: "danger",
              duration: 10000
            })
            this.router.navigate(['auth/login'])
          } 
        }
      })
  }
}
