
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import 'rxjs/Rx'
import { CookieService } from 'ngx-cookie-service'

const ApiEndpoint = '/api/v2/reports/'
@Injectable()
export class ReportService {
    constructor(private http: HttpClient, private cookieService: CookieService) { }

    getHttpHeaders() {

        return new HttpHeaders({
            'Authorization': 'Bearer ' + this.cookieService.get('access_token'),
            'Content-Type': 'application/json',
            'accept': '*/*'
        })
    }

    exportAIPs(params? : HttpParams): Observable<any> {
        return this.http.get(ApiEndpoint + 'aip', {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            }),
            params: params
        })
            .map(response => response as any || null)
    }

    exportRegisteredStudents(params? : HttpParams): Observable<any> {
        return this.http.get(ApiEndpoint + 'regstudent', {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            }),
            params: params
        })
            .map(response => response as any || null)
    }

    exportImportedStudents(params? : HttpParams): Observable<any> {
        return this.http.get(ApiEndpoint + 'student', {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.cookieService.get('access_token')
            }),
            params: params
        })
            .map(response => response as any || null)
    }
}


