import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {

  @Input()
  notiData: object;



  @Output() deleteNotificationCardEvent = new EventEmitter();

  title: string;
  content: string;
  sender: object;
  target: object;
  aipId: string;
  selfRefTarget: string;
  selfRef: string;
  createDate: string;
  broadcastObj: object;
  broadcastTitle: string;

  constructor(private notificationService: NotificationService) {
    this.title  = '';
  }

  ngOnInit() {
    if (this.notiData) {
      this.title = (this.notiData['type'] as string).toUpperCase().split('_').join(' ');
      if ((this.notiData['type'] as string).toLowerCase().includes('broadcast')) {
        this.notificationService.getBroadcastContent(this.notiData['broadcastId']).subscribe(data => {
          this.broadcastObj = data;
          this.broadcastTitle = data['title'];
          this.selfRef = 'You';
          this.content = ' received a broadcast message.';
        });
      } else {
        if (((this.notiData['student']  as object)['username'] as string) === (this.notiData['to'] as string)) {
          this.target  = this.notiData['student']  as object;
          this.sender  = this.notiData['banker']  as object;
        } else {
          this.target  = this.notiData['banker']  as object;
          this.sender  = this.notiData['student']  as object;
          this.createNotiContentForBanker();
        }
      }
      this.createDate = new Date(this.notiData['createdAt'] as string).toLocaleDateString('en-GB', {
        day: '2-digit', month: 'short', year: 'numeric'
      }).replace(/ /g, '-');
    }
  }

  createNotiContentForBanker() {
    if ((this.notiData['type'] as string).toLowerCase().includes('loan')) {
      this.content = ' ' + (this.notiData['type'] as string).split('_')[1].toLowerCase() + ' the ';
      this.aipId = 'AIP ' + (this.notiData['aipId'] as string) + '.';
    } else {
      this.content = ' sent a message about AIP ' + (this.notiData['aipId'] as string) + ' to ';
      this.selfRefTarget = 'you.';
    }

  }

  dateParse(value: string) : string {
    const d = value.split('T')[0];
    const dl = d.split('-');
    return dl[1] + '/' + dl[2] + '/' + dl[0];
  }

  deleteNotification() {
    this.notificationService.updateNotificationState(this.notiData['notificationId'], {'status': 'deleted'}).subscribe(response => {
      if (response.error) {
        alert(response.error);
      } else {
        this.deleteNotificationCardEvent.emit(this.notiData['notificationId']);
      }
    });
  }
}
